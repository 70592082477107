<template>
    <div style="margin:-12px;">
        <!-- app bar -->
        <top-nav title="Configuración de cliente">
            <template v-slot:actions>
                <v-btn icon @click="saveCustomer"><v-icon>mdi-floppy</v-icon></v-btn>
            </template>
        </top-nav>

        <div v-if="customer != null">

            <v-card flat tile color="grey lighten-5">
                <v-card-text>
                    <v-alert border="left" text dense type="warning" :value="alertShow" dismissible>{{ alertMessage }}</v-alert>
                    <v-form ref="form" v-model="formValid">
                        <!-- GENERAL INFORMATION -->
                        <v-card-subtitle class="pl-0 subtitle-1">General Information</v-card-subtitle>

                        <v-row>
                            <v-col cols="6">
                                <v-text-field v-model="customer.firstName" label="First name" :rules="firstNameRules" outlined background-color="white"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field v-model="customer.lastName" label="Last name" :rules="lastNameRules" outlined background-color="white"></v-text-field>
                            </v-col>
                        </v-row>

                        <v-text-field v-model="customer.email" type="email" label="Email" :rules="emailRules" outlined background-color="white" :disabled="!isNewCustomer"></v-text-field>
                        <v-text-field v-model="customer.phone" type="tel" label="Phone" v-mask="'(###) ###-####'" :rules="phoneRules" outlined background-color="white"></v-text-field>

                        <v-select v-model="customer.gender" :items="genders" label="Gender" outlined background-color="white"></v-select>

                        <!-- HEALTH INFORMATION -->
                        <v-card-subtitle class="pl-0 subtitle-1">Health Information</v-card-subtitle>

                        <span class="caption">Height</span>
                        <v-row no-gutters class="mt-2">
                            <v-col cols="6">
                                <v-text-field v-model="customer.heightFeet" label="Feet" outlined background-color="white" :rules="heightFeetRules" suffix="ft" class="txt-left"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field v-model="customer.heightInches" label="Inches" outlined background-color="white" :rules="heightInchesRules" suffix="in" class="txt-right"></v-text-field>
                            </v-col>
                        </v-row>

                        <v-text-field v-model="customer.weight" label="Weight" suffix="lbs" outlined :rules="weightRules" background-color="white"></v-text-field>

                        <!-- ADDRESSES -->
                        <v-card-subtitle class="pl-0 subtitle-1">Billing Address</v-card-subtitle>
                        
                        <address-component :value="billingAddress" editable @input="setBillingAddress" ref="billingAddress"></address-component>


                        <v-card-subtitle class="pl-0 subtitle-1">Shipping Address</v-card-subtitle>

                        <address-component :value="shippingAddress" editable @input="setShippingAddress" ref="shippingAddress"></address-component>

                        <v-switch v-model="customer.isAdmin" label="Es administrador del Petu App"></v-switch>
                        <v-switch v-model="customer.isPetuPunchAdmin" label="Es administrador de Petu Punch"></v-switch>

                        <v-text-field v-model="customer.strikes" label="Strikes" outlined :rules="strikeRules" background-color="white" @keydown="intOnly"></v-text-field>
                    </v-form>
                </v-card-text>
            </v-card>

            <v-overlay :value="saving">
                <v-card flat color="white" min-width="200">
                    <v-card-text>
                        <div class="subtitle-1 grey--text text--darken-2 text-center mb-2">Saving...</div>
                        <v-progress-linear color="primary" indeterminate rounded height="6"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-overlay>
        </div>

        <v-skeleton-loader class="mx-auto" type="article, article, article, actions" v-else></v-skeleton-loader>
    </div>
</template>

<script>
import TopNav from '@/components/admin/TopNav';
import { mapGetters } from 'vuex';
import { PetuCustomer } from '@/classes/customer';
import AddressComponent from '@/components/AddressComponent';
import { mask } from 'vue-the-mask';
import { _st } from '@/softech';
import API from '@aws-amplify/api';

export default {
    props: {
        customerId      : { type: String, default: '' }
    },
    data() {
        return {
            customer            : null,
            genders             : [
                { text: 'Masculino', value: 'm' }, 
                { text: 'Femenino', value: 'f' }, 
                { text: 'N/A', value: '' }
            ],
            heightFeet          : '',
            heightInches        : '',

            saving              : false,
            formValid           : true,

            // rules
            firstNameRules      : [ v => !!v || 'Primer nombre es requerido' ],
            lastNameRules       : [ v => !!v || 'Apellido es requerido' ],
            emailRules          : [
                v => !!v || 'E-mail is required',
                v => _st.isEmail( v ) || 'E-mail tiene que ser válido',
            ],
            phoneRules          : [ 
                v => !!v || 'Phone is required',
                v => _st.isPhone( v ) || 'Teléfono tiene que ser válido', 
            ],

            // corregir esto, no graba pq no pasa la validación cuando estos campos están vacíos
            heightFeetRules     : [v => {
                if( !_st.isEmpty( v ) ) 
                    return /^[2-8]{1}$/.test(v) || 'Los pies tienen que ser válidos';

                return true;
            }],
            // [ v => /^[2-8]{1}$/.test(v) || 'Feet must be valid' ],
            heightInchesRules   : [v => {
                if( !_st.isEmpty( v ) )
                    return this.validateInches( v ) || 'Las pulgadas tienen que ser válidas';

                return true;
            }],
            weightRules         : [v => {
                if( !_st.isEmpty( v ) )
                    return /^[0-9]{2,3}$/.test(v) || 'El peso tiene que ser válido';

                return true;
            }],
            strikeRules         : [v => {
                if( !_st.isEmpty( v ) )
                    return /^[0-3]{1}$/.test(v) || 'no puede ser menor de 0 ni mayor de 3';

                return true;
            }],

            alertShow           : false,
            alertMessage        : '',
        }
    },
    computed: {
        ...mapGetters({ 
            user: 'auth/user' 
        }),
        isNewCustomer() {
            return _st.isNUE( this.customerId );
        },
        billingAddress() {
            return {
                name: `${this.customer.firstName} ${this.customer.lastName}`,
                line1: this.customer.billingAddress1,
                line2: this.customer.billingAddress2,
                city: this.customer.billingCity,
                state: this.customer.billingState,
                zipCode: this.customer.billingZipcode
            }
        },
        shippingAddress() {
            return {
                name: `${this.customer.firstName} ${this.customer.lastName}`,
                line1: this.customer.shippingAddress1,
                line2: this.customer.shippingAddress2,
                city: this.customer.shippingCity,
                state: this.customer.shippingState,
                zipCode: this.customer.shippingZipcode
            }
        },
    },
    async mounted() {
        // load product
        let customer = new PetuCustomer();

        if( _st.isNUE( this.customerId ) ) {
            this.customer = customer.data;
            return;
        }

        await customer.load( this.customerId );
        this.customer = customer.data;
        this.heightFeet = customer.data.heightFeet;
        this.heightInches = customer.data.heightInches;
    },
    methods: {
        getCustomerInitials() {
            let c = new PetuCustomer( this.customer );
            return c.getInitials();
        },
        validateInches( inches ) {
            return inches >= 0 && inches <= 11;
        },
        async saveCustomer() {
            this.alertShow = false;

            // this.$refs.form.resetValidation();
            this.$refs.billingAddress.resetValidation();
            this.$refs.shippingAddress.resetValidation();

            this.$refs.form.validate();
            
            // check if form is validate
            if (!this.formValid) return

            try {
                let customer = new PetuCustomer( this.customer );
                customer.data.height = `${this.heightFeet}' ${this.heightInches}''`;
                const isNew = _st.isNU( customer.data.id ) || _st.isEmpty( customer.data.id );

                this.saving = true;
                await customer.save();

                if( isNew ) {
                    this.$router.push( '/secure/customer/' + customer.data.id );
                    return;
                }

                this.saving = false;
                
                this.customer = customer.data;

                this.heightFeet = customer.data.heightFeet;
                this.heightInches = customer.data.heightInches;

                this.$root.$emit('alert', {
                    status: 'success',
                    message: 'Cliente fue guardado'
                });
            }
            catch(error) {
                if( !_st.isNU( error.error ) && !error.error && !_st.isNUE( error.message ) ) {
                    this.alertShow = true;
                    this.alertMessage = error.message;
                    this.$vuetify.goTo(0, {
                        duration: 900,
                        offset: 0,
                        easing: 'easeInOutCubic',
                    });
                }

                this.saving = false;
            }
        },
        intOnly(e) {
            const key       = e.keyCode;
            const ctrlFlag  = e.ctrlKey || e.metaKey;
            
            if ([46, 8, 9, 27, 13].includes(key) ||    // allow: backspace, delete, tab, escape and enter
                (key == 65 && ctrlFlag) ||                  // allow: ctrl/cmd+A
                (key == 67 && ctrlFlag) ||                  // allow: ctrl/cmd+C
                (key == 88 && ctrlFlag) ||                  // allow: ctrl/cmd+X
                (key >= 35 && key <= 39)) {                 // allow: home, end, left, right
                return;                                     // do nothing
            }
      
            /** if it's a number, stop the keypress */
            if ((e.shiftKey || (key < 48 || key > 57)) && (key < 96 || key > 105)) {
                e.preventDefault();
            }
        },
        setBillingAddress( address ) {
            this.customer.billingAddress1 = address.line1;
            this.customer.billingAddress2 = address.line2;
            this.customer.billingCity = address.city;
            this.customer.billingState = address.state;
            this.customer.billingZipcode = address.zipCode;
        },
        setShippingAddress( address ) {
            this.customer.shippingAddress1 = address.line1;
            this.customer.shippingAddress2 = address.line2;
            this.customer.shippingCity = address.city;
            this.customer.shippingState = address.state;
            this.customer.shippingZipcode = address.zipCode;
        },
    },
    components: {
        TopNav,
        AddressComponent
    },
    directives: {
        mask
    }
}
</script>

<style lang="scss" scoped>
.content {
  margin: 120px 10px 0 10px;
  background: rgb(238,58,140);
  background: linear-gradient(187deg, rgba(238,58,140,1) 0%, rgba(246,137,38,1) 100%);
  border-radius: 20px;
}
.avatar {
    margin-top: -75px;
}

.txt-left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.txt-middle {
    border-radius: 0;
}
.txt-right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
</style>